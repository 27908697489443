/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import { MailIcon, XIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Api from '@/services/Api';

type Props = {
  onClose: () => void;
};

const ForgotPassword: React.FC<Props> = ({ onClose }: Props) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    if (email) {
      try {
        const response = await Api.post('v2/auth/forgot-password', {
          locale,
          email,
        });

        if (response?.message === 'success') {
          setMessage(t('forgotPasswordSuccess'));
        } else {
          setMessage(t('forgotPasswordError'));
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setMessage(t('forgotPasswordError'));
      }
    } else {
      setMessage(t('emailInvalid'));
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col max-w-md my-2 text-sm">
        <XIcon onClick={onClose} className="cursor-pointer w-6 h-6 self-end" />
        <form className="space-y-8" onSubmit={handleSubmit}>
          <p className="text-lg font-medium">{t('forgotPassword')}</p>
          <p className="leading-normal">{t('forgotPasswordDescription')}</p>
          <div className="flex flex-col space-y-2">
            <label className="relative font-medium block">
              {t('email')}
              <MailIcon className="pointer-events-none w-6 h-6 absolute top-12 transform -translate-y-1/2 left-4" />
              <input
                className="form-input w-full pl-14 mt-1 rounded-md bg-white bg-opacity-10 border-none py-3 px-4 focus:ring-1 focus:ring-accent shadow-lg"
                type="email"
                placeholder={t('email')}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>
          <span
            className={message ? 'flex flex-col text-center text-gray-200' : ''}
          >
            {message}
          </span>
          <button
            className="bg-accent w-full rounded-md py-3"
            disabled={loading}
          >
            {t('restorePassword')}
          </button>
        </form>
      </div>
    </>
  );
};

export default ForgotPassword;
