/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { GetServerSideProps, NextPage } from 'next';
import { MailIcon, LockOpenIcon } from '@heroicons/react/outline';
import router, { useRouter } from 'next/router';
import ReactHtmlParser from 'react-html-parser';
import { getCsrfToken, signIn, getSession } from 'next-auth/client';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ForgotPassword from '@/components/ForgotPassword/ForgotPassword';
import { event } from 'utils/gtag';
import { convertLanguageCodeToName } from 'utils';

type Props = {
  csrfToken: string;
};

const Login: NextPage<Props> = ({ csrfToken }: Props) => {
  const { t } = useTranslation(['common', 'profile']);
  const { locale, query } = useRouter();
  const [loginError, setLoginError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const schema = yup.object().shape({
    email: yup
      .string()
      .required(t('profile:emailRequired'))
      .email(t('profile:emailInvalid')),
    password: yup.string().required(t('profile:passwordRequired')),
  });

  const handleForgotPassword = () => setShowForgotPassword(!showForgotPassword);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    event({
      event: 'login',
      data: {
        brand: 'Butaca TV',
        endpoint_group: 'CTV',
        subbrand: 'Butaca TV',
        endpoint: 'Butaca TV',
        user_id: data.email,
        language: convertLanguageCodeToName(locale),
      },
    });
    setLoading(true);
    setLoginError('');
    try {
      const response = await signIn('login', {
        ...data,
        callbackUrl: `${window.location.origin}`,
        redirect: false,
      });

      // @ts-ignore
      if (response?.error) {
        // @ts-ignore
        const serverError = t(response?.error, {
          site_url: process.env.NEXT_PUBLIC_SITE_URL,
        });
        setLoginError(serverError);
      }

      if (query.refer) {
        await router.push(String(query.refer));
        //@ts-ignore
      } else if (response?.url) {
        await router.push('/');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Head>
        <title>{`${t('profile:login')} | Butaca TV`}</title>
      </Head>
      <div className="flex flex-col w-full my-10 text-sm lg:max-w-md">
        {showForgotPassword ? (
          <ForgotPassword onClose={handleForgotPassword} />
        ) : (
          <div className="flex flex-col">
            <form className="space-y-8" onSubmit={handleSubmit(onSubmit)}>
              {query.refer && (
                <span className="flex flex-col text-center text-gray-200">
                  {t('profile:linkTvRequired')}
                </span>
              )}
              <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
              <div className="flex flex-col space-y-2">
                <label className="relative font-medium block">
                  {t('profile:email')}
                  <MailIcon className="pointer-events-none w-6 h-6 absolute top-12 transform -translate-y-1/2 left-4" />
                  <input
                    className="form-input w-full pl-14 mt-1 rounded-md bg-white bg-opacity-10 border-none py-3 px-4 focus:ring-1 focus:ring-accent shadow-lg"
                    type="email"
                    placeholder={t('profile:email')}
                    {...register('email')}
                  />
                </label>
                <span
                  className={errors?.email?.message ? 'text-danger' : 'hidden'}
                >
                  {errors?.email?.message}
                </span>
              </div>
              <div className="flex flex-col space-y-2">
                <label className="relative font-medium block">
                  {t('password')}
                  <LockOpenIcon className="pointer-events-none w-6 h-6 absolute top-12 transform -translate-y-1/2 left-4" />
                  <input
                    className="form-input w-full pl-14 rounded-md mt-1 bg-white bg-opacity-10 border-none py-3 px-4 focus:ring-1 focus:ring-accent shadow-lg"
                    type="password"
                    placeholder={t('profile:password')}
                    {...register('password')}
                  />
                </label>
                <span
                  className={
                    errors?.password?.message ? 'text-danger' : 'hidden'
                  }
                >
                  {errors.password?.message}
                </span>
              </div>
              <span
                className={
                  loginError
                    ? 'flex flex-col text-center text-gray-200'
                    : 'hidden'
                }
              >
                {ReactHtmlParser(loginError)}
              </span>
              <button
                className="bg-accent w-full rounded-md py-3"
                disabled={loading}
              >
                {loading ? `${t('common:loading')}...` : t('profile:login')}
              </button>
            </form>
            <div className="text-center py-4">
              <Link href="/reset-password">
                <span className="font-sm cursor-pointer">
                  {t('profile:forgotPassword')}
                </span>
              </Link>
            </div>
            <div className="m-10" />
          </div>
        )}
      </div>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const { req, locale } = context;
  const session = await getSession({ req });

  if (session?.accessToken) {
    return {
      redirect: { permanent: false, destination: '/' },
    };
  }

  return {
    props: {
      csrfToken: await getCsrfToken(context),
      ...(await serverSideTranslations(locale, ['common', 'profile'])),
    },
  };
};

export default Login;
